<template>
  <div class="mt-2">
    <div v-if="getItems.length">
      <CDataTable
        :striped="false"
        :bordered="false"
        :small="true"
        :items="getItems"
        :fields="displayFields"
        :items-per-page="10"
        :sorter="false"
        :tableFilter="false"
        pagination
        ref="offer-cand-tab"
      >
        <template #job_title="{ item }">
          <td>
            {{ item.job_title
            }}<span
              class="text-primary h6 mx-1"
              v-if="offerAcceptedJobs.includes(item.job_id)"
              >[Accepted]</span
            >
          </td>
        </template>
        <template #action="{ item }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                v-show="collapseIndex == item.job_id"
                square
                class="mr-1 btn-outline-primary btn-sm"
                :name="`offer-upload-collapse-job-${item.job_id}`"
                @click="toggleDetails(item)"
                v-c-tooltip="{
                  content: 'Collapse all',
                }"
              >
                <em class="fa fa-minus"></em>
              </CButton>
              <CButton
                v-show="collapseIndex != item.job_id"
                square
                class="mr-1 btn-outline-primary btn-sm"
                :name="`offer-upload-list-job-${item.job_id}`"
                @click="toggleDetails(item)"
                v-c-tooltip="{
                  content: 'List all',
                }"
              >
                <em class="fa fa-plus"></em>
              </CButton>
            </div>
          </td>
        </template>
        <template #details="{ item }">
          <CCollapse
            :show="collapseIndex == item.job_id"
            :duration="0"
            class="p-2"
          >
            <div v-if="item.actions.length">
              <table class="table table-striped">
                <colgroup>
                  <col span="1" style="width: 25%" />
                  <col span="1" style="width: 25%" />
                  <col span="1" style="width: 15%" />
                  <col span="1" style="width: 15%" />
                  <col span="1" style="width: 20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>File Name</th>
                    <th>Response Required</th>
                    <th>Candidate Return</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in item.actions" :key="index">
                    <td>
                      {{ data.type_name
                      }}<i
                        class="fa-solid fa-circle-info mx-1"
                        v-c-tooltip="{
                          content: data.comments,
                          placement: 'right',
                          appendToBody: true,
                        }"
                      ></i>
                    </td>
                    <td>{{ data.library_name }}</td>
                    <td>
                      <CButton
                        class="btn-link mb-1 cursor-pointer px-0 text-left"
                        v-if="data && data.document_name && data.link_to_doc"
                        @click="preview(data)"
                      >
                        {{ data.document_name }}
                      </CButton>
                      <p v-else>--</p>
                    </td>
                    <td class="text-center">
                      {{ data.action_required ? "Yes" : "No" }}
                    </td>
                    <td>
                      <div
                        class="d-flex"
                        v-for="(returnAction, r_index) in data.returnAction"
                        :key="returnAction.document_action_id"
                      >
                        <CButton
                          class="
                            btn-link
                            mb-1
                            cursor-pointer
                            px-0
                            text-left text-nowrap text-truncate
                          "
                          style="width: 100px"
                          :title="returnAction.document_name"
                          @click="preview(returnAction)"
                        >
                          {{ returnAction.document_name }}
                        </CButton>
                        <CButton
                          class="btn-link px-0 d-flex ml-1"
                          @click="deleteDocuments(returnAction)"
                        >
                          <i class="fas fa-trash"></i>
                        </CButton>
                        <CButton
                          class="btn-link p-0 mx-1"
                          title="Add another document"
                          v-if="
                            data.expects_multi_documents &&
                            !isShowAdditionalUpload &&
                            r_index === data.returnAction.length - 1
                          "
                          @click="
                            isShowAdditionalUpload = data.document_action_id
                          "
                        >
                          <i class="fa-solid fa-circle-plus fs-20"></i>
                        </CButton>
                      </div>
                      <div
                        v-if="
                          (!data.returnAction.length ||
                            (data.expects_multi_documents &&
                              isShowAdditionalUpload ===
                                data.document_action_id)) &&
                          data.action_required
                        "
                      >
                        <input
                          :id="`upload-document-${data.document_action_id}-${index}`"
                          hidden
                          :name="`upload-document-${data.document_action_id}-${index}`"
                          type="file"
                          :ref="`file-ref-${data.document_action_id}-${index}`"
                          :accept="data.allowed_extensions"
                          @change="selectedFile($event, data, index)"
                        />
                        <label
                          :name="`upload-document-${data.document_action_id}-${index}`"
                          :for="`upload-document-${data.document_action_id}-${index}`"
                          class="btn btn-primary mb-0"
                        >
                          Upload
                        </label>
                        <CButton
                          class="btn-link p-0 mx-1"
                          title="Remove"
                          v-if="
                            isShowAdditionalUpload === data.document_action_id
                          "
                          @click="isShowAdditionalUpload = null"
                        >
                          <i
                            class="fa-solid fa-circle-minus text-danger fs-20"
                          ></i>
                        </CButton>

                        <CIcon
                          v-else
                          :ref="`upload-document-${data.document_action_id}-${index}`"
                          name="cil-info"
                          class="hinticon-multi-select my-1 cursor-pointer mx-0"
                          v-c-tooltip="{
                            placement: 'left',
                            content:
                              'Please Upload this Documents as Requested',
                            appendToBody: true,
                          }"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="
                  col-lg-11 col-md-11
                  container-md
                  d-flex
                  justify-content-end
                  p-2
                "
              >
                <CButton
                  v-if="
                    !offerAcceptedJobs.includes(item.job_id) &&
                    item.action_type == 'offer'
                  "
                  class="btn-primary"
                  title="Offer Acceptance"
                  color="primary"
                  @click="offerUploadCheck(item)"
                >
                  Accept and Acknowledge
                </CButton>
              </div>
            </div>
            <p v-else class="text-muted h5 text-center p-4">Not Yet Uploaded</p>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
    <p v-else class="text-center text-muted p-2 h6">No Document Found</p>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
      v-if="deleteConfirmModal.isShowPopup"
    />
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      buttonLabel="Apply"
      @modalCallBack="cropperModalCallBack"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
      v-if="previewModal.isShowPopup"
    />
    <Modal
      :modalTitle="notificationModal.modalTitle"
      :modalColor="notificationModal.modalColor"
      :modalContent="notificationModal.modalContent"
      :isShowPopup="notificationModal.isShowPopup"
      :buttons="notificationModal.buttons"
      :modalCallBack="notificationModalCallBack"
      :closeOnBackdrop="false"
      v-if="notificationModal.isShowPopup"
    />
    <CModal
      color="primary"
      :show.sync="commentsModal.isShowPopup"
      v-show="commentsModal.isShowPopup"
    >
      <template #header>
        <h5>{{ commentsModal.title }}</h5>
      </template>
      <div>
        <ValidationObserver ref="comments" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="comments">
            <CRow>
              <CCol lg="12">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Comment</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextAreaInput
                        name="comments"
                        :value="commentsModal.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="commentModalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="commentModalCallBack(true)"
          >Submit</CButton
        >
      </template>
    </CModal>
  </div>
</template>
<script>
import moment from "moment";
import Modal from "@/components/reusable/Modal";
import { mapActions } from "vuex";
import CropperModal from "@/components/reusable/CropperModal";
import PreviewModal from "@/components/reusable/PreviewModal";
import { BASE_URL } from "@/service_urls";
import pdf from "vue-pdf";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    document: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Modal,
    CropperModal,
    PreviewModal,
    TextAreaInput,
  },
  data() {
    return {
      collapseIndex: null,
      displayFields: [
        { key: "job_title", label: "Job Title", _style: "width: 70%" },
        { key: "action", label: "Action", _style: "width: 20%" },
      ],
      uploadAction: {},
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      selectedFileRef: null,
      cropperModal: {
        fileData: {},
        isShowPopup: false,
        index: null,
      },
      isShowAdditionalUpload: null,
      iframe: {
        url: null,
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      notificationModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["OK"],
      },
      commentsModal: {
        title: "",
        isShowPopup: false,
        status: null,
        comments: null,
      },
      offerAcceptedJobs: [],
    };
  },
  computed: {
    getItems() {
      const r = this.document.map((v) => ({
        job_id: v?.job_id,
        job_title: `${v?.job?.job_title} | ${v?.job?.display_uid}`,
        candidate_uid: v?.candidate_uid,
        action_type: v?.action_type,
        actions: v.actions.map((e) => ({
          document_action_id: e?.document_action_id,
          data: e,
          created_on: e?.created_on,
          type_name:
            e?.document_library?.customer_document_type?.type_name ||
            e?.customer_document_type?.type_name,
          document_name:
            e?.document_name ||
            e?.document_library?.organisation_document?.document_name,
          action_required: e?.action_required,
          link_to_doc:
            e?.link_to_doc ||
            e?.document_library?.organisation_document?.link_to_doc,
          ext:
            e?.document_ext?.document_ext ||
            e?.document_library?.organisation_document?.document_ext
              ?.document_ext,
          comments: e?.document_library?.comments || e?.comments,
          library_name: e?.document_library?.library_name,
          returnAction: e?.returnAction,
          expects_multi_documents:
            e?.document_library?.expects_multi_documents || false,
          allowed_extensions: this.toAccept(
            e?.document_library?.customer_document_type?.allowed_extensions
          ),
        })),
        data: v,
      }));
      return r;
    },
  },
  methods: {
    ...mapActions([
      "deleteDocumentAction",
      "showToast",
      "createDocumentActionFiles",
      "getDocumentAction",
      "fetchCandidateAndJobStatus",
      "acceptOfferByCandidate",
    ]),
    formatDate(data) {
      if (data) return moment(data).format("DD MMM YYYY");
      return "--";
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    toggleDetails(item) {
      if (this.collapseIndex === item.job_id) {
        this.collapseIndex = null;
        this.tooltipHide();
        return;
      }
      this.collapseIndex = item.job_id;
      this.selectedJob = item;
      this.tooltipHide();
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${data?.customer_document_type?.type_name} | ${data?.document_name} ?`;
      this.deleteConfirmModal.data = data;
      this.deleteConfirmModal.isShowPopup = true;
    },
    fetchDocActionsForCandidate() {
      const { customer_uid, candidate_uid, organisation_id } = this.profile;
      let payload = {
        customer_uid,
        candidate_uid,
      };
      this.isShowAdditionalUpload = null;
      this.getDocumentAction({
        ...payload,
        action_type__in: ["offer", "licensing"],
      });
    },
    async deleteModalCallBack(action) {
      this.deleteConfirmModal.isShowPopup = false;
      if (action === "Confirm") {
        let { document_action_id, organisation_id } =
          this.deleteConfirmModal.data;
        await this.deleteDocumentAction({
          document_action_id,
          organisation_id,
        }).then((res) => {
          this.fetchDocActionsForCandidate();
        });
      }
      this.deleteConfirmModal.selectedData = {};
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1].toLowerCase();
    },
    handleFileUploads(fileData, data) {
      const { file_name, file } = fileData;
      const {
        data: { action_type, organisation_id, job_id, document_library },
        document_action_id,
      } = data;
      const { customer_uid, candidate_uid } = this.profile;
      let finalPayload = {
        action_type: action_type,
        actioned_by: "candidate",
        previous_action_id: document_action_id,
        customer_uid,
        candidate_uid,
        organisation_id,
        comments: null,
        job_id,
        customer_document_type_id: document_library?.customer_document_type_id,
        file,
        file_name,
      };
      if (finalPayload && finalPayload.file) {
        this.createDocumentActionFiles(finalPayload).then(() => {
          if (this.$refs[this.selectedFileRef])
            this.$refs[this.selectedFileRef].value = "";
          this.fetchDocActionsForCandidate();
        });
      }
    },
    selectedFile(event, data, index) {
      const size = event.target.files[0]?.size;
      this.selectedFileRef = `file-ref-${data?.document_action_id}-${index}`;
      if (Math.round(size / (1024 * 1024)) <= 5) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = data.allowed_extensions;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.isShowPopup = true;
            this.cropperModal.fileData = file;
            this.cropperModal.data = data;
          } else {
            const { name: file_name } = file;
            this.handleFileUploads({ file, file_name }, data);
          }
        } else {
          if (this.$refs[this.selectedFileRef])
            this.$refs[this.selectedFileRef].value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeString}`,
          });
        }
      } else {
        if (this.$refs[this.selectedFileRef])
          this.$refs[this.selectedFileRef].value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
      }
    },
    toAccept(data) {
      if (data && Array.isArray(data)) {
        return data.map((v) => `.${v.toLowerCase()}`).join(",");
      }
      return data;
    },
    cropperModalCallBack(action, payload) {
      if (action === false) {
        if (this.$refs[this.selectedFileRef])
          this.$refs[this.selectedFileRef].value = "";
        this.cropperModal.fileData = {};
      } else {
        let { fileBlob, file_name } = payload;
        const { data } = this.cropperModal;
        this.handleFileUploads({ file: fileBlob, file_name }, data);
      }
      this.cropperModal.isShowPopup = false;
    },
    preview(data) {
      const previewData = this.extractPreviewDoc(data);
      if (previewData) this.previewModalFun(previewData);
    },
    previewModalFun(data) {
      const _baseUrl = BASE_URL;
      const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      // const _base_domain = "https://" + window.location.host;
      const { document_ext: ext } = data;
      this.iframe.url =
        _baseUrl.substring(0, _baseUrl.indexOf("/api")) + data.link_to_doc;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link_to_doc;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          _base_domain + data.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(ext)) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link_to_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    extractPreviewDoc(data) {
      if (data && data?.actioned_by === "candidate") {
        const {
          link_to_doc,
          document_ext: { document_ext },
          document_name,
        } = data;
        return { link_to_doc, document_ext, document_name };
      } else if (data && data?.document_library) {
        const link_to_doc =
          data?.document_library?.organisation_document?.link_to_doc;
        const document_ext =
          data?.document_library?.organisation_document?.document_ext
            ?.document_ext;
        const document_name =
          data?.document_library?.organisation_document?.document_name;
        return { link_to_doc, document_ext, document_name };
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    async checkIsJobOfferAccepted(data) {
      if (data?.action_type != "offer") return;
      const { job_id, candidate_uid } = data;
      const payload = {
        job_id,
        status_id__in: [58],
        sub_status_id__in: [59, 60],
        candidate_uid,
        order_by: "-action_detail_id",
        limit: 1,
      };
      await this.fetchCandidateAndJobStatus(payload).then((res) => {
        if (res.status == 200) {
          const { data } = res;
          if (
            data.length &&
            data[0].status_id === 58 &&
            data[0].sub_status_id === 59 &&
            !data[0].completd_by
          ) {
            return true;
          } else {
            this.offerAcceptedJobs = [...this.offerAcceptedJobs, job_id];
            return false;
          }
        }
        return false;
      });
    },
    offerUploadCheck(data) {
      const isReActPend = data?.actions.some(
        (v) => v?.action_required && v?.returnAction?.length === 0
      );
      if (isReActPend) {
        this.notificationModal.modalTitle = "Notification";
        this.notificationModal.modalContent = `Please Upload the documents for the acceptance!`;
        this.notificationModal.buttons = ["OK"];
      } else {
        this.notificationModal.modalTitle = "Confirmation";
        this.notificationModal.modalContent =
          "Please confirm you have signed the required document and you are ready to accept this offer.";
        this.notificationModal.buttons = ["No", "Yes"];
      }
      this.notificationModal.isShowPopup = true;
      this.notificationModal.data = data;
    },
    async notificationModalCallBack(action) {
      this.notificationModal.isShowPopup = false;
      if (action === "Yes") {
        this.commentsModal.isShowPopup = true;
        this.commentsModal.status = {
          update_status: 58,
          update_sub_status: 60,
        };
        this.commentsModal.title = "Offer Acceptence";
        const comments = "Offer Accepted";
        this.commentsModal.comments = comments;
        this.commentsModal.data = this.notificationModal.data;
        const { job_id, candidate_uid } = this.notificationModal.data;
        this.commentsModal.payload = {
          payloadData: {
            job_id,
            candidate_uid,
            payload: { comments },
          },
          updateStatusSubStatus: {
            update_status: 58,
            update_sub_status: 60,
          },
        };
      }
    },
    handleInput(name, value) {
      Vue.set(this.commentsModal.payload.payloadData.payload, name, value);
    },
    async commentModalCallBack(action) {
      if (action) {
        const isValid = await this.$refs.comments.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please fill mandatory fields!",
          });
          return;
        }
        const { payload } = this.commentsModal;
        await this.acceptOfferByCandidate(payload);
        const { data } = this.commentsModal;
        this.checkIsJobOfferAccepted(data);
      }
      this.commentsModal.isShowPopup = false;
    },
  },
  filters: {
    removeExtension(data) {
      if (data) return data.split(".").slice(0, -1).join(".");
      return "--";
    },
  },
  mounted() {
    this.getItems.forEach((element) => {
      this.checkIsJobOfferAccepted(element);
    });
  },
};
</script>