<template>
    <div class="pb-2">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.languageScores =
            !$parent.isExpand.languageScores),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas fa-angle-right arrow"
            :class="{'down':$parent.isExpand.languageScores}"
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">Language Scores</h5>
    </a>

    <div v-if="$parent.isExpand.languageScores">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <th>Language</th>
            <th>Test Name</th>
            <th>Latest Level</th>
            <th>Required Level</th>
            <th>Predicated Pass Date</th>
            <th>Exam Booked</th>
            <th></th>
          </thead>
          <tbody class="multi-records">
 
            <template v-for="(scores, index) in getCandidateTestScores">
            
              <tr>
                <td>{{ scores.language }}</td>
                <td>{{ scores.test_acronym }}</td>
                <td>
                  <span class="text-nowrap">
                    {{
                      getResultTestScore(
                        scores.current_score,
                        scores.test_id
                      ) | grade
                    }}
                    ({{
                      getResultTestScore(
                        scores.current_score,
                        scores.test_id
                      ) | cefr
                    }})</span
                  >
                </td>
                <td>
                  {{ requiredScore(scores._rawData) | grade }}({{
                    requiredScore(scores._rawData) | cefr
                  }})
                </td>
                <td>{{ scores.predicted_date | dateFormat }}</td>
                <td>{{ scores.official_test_booked | dateFormat }}</td>
                <td>
                  <div
                    class="text-primary h4"
                    v-c-tooltip="{
                      content: 'Expand Scores',
                    }"
                  >
                    <em
                      :class="
                        !collapsedIndex.includes(index)
                          ? 'fa-solid fa-circle-plus'
                          : 'fa-solid fa-circle-minus'
                      "
                      @click="toggleInnerTable(index)"
                    ></em>
                  </div>
                </td>
              </tr>
              <tr v-if="collapsedIndex.includes(index)" class="top-border">
                <td colspan="8" class="p-0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Reading</th>
                        <th>Listening</th>
                        <th>Speaking</th>
                        <th>Writing</th>
                        <th>Result</th>
                        <th>CEFR</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            {
                              skills: skill,
                              date_obtained,
                              test_type,
                              avg,
                            },
                            subIndex
                          ) in scores.skills"
                          class="top-border"
                        >
                          <td>{{ date_obtained | dateConvert }}</td>
                          <td>{{ test_type }}</td>
                          <td>
                            <span
                              class="text-nowrap"
                              v-if="extractExamScores(skill, 'Reading')"
                            >
                              <span v-if="scores.test_id != 2"
                                >{{
                                  extractExamScores(skill, "Reading")
                                    | score
                                }}
                                -
                              </span>
                              <span
                                :class="{
                                  'text-primary grade-text':
                                    scores.test_id != 2,
                                }"
                                >{{
                                  extractExamScores(skill, "Reading")
                                    | grade
                                }}({{
                                  extractExamScores(skill, "Reading")
                                    | cefr
                                }})</span
                              >
                            </span>
                            <span v-else>--</span>
                          </td>
                          <td>
                            <span
                              class="text-nowrap"
                              v-if="extractExamScores(skill, 'Listening')"
                            >
                              <span v-if="scores.test_id != 2"
                                >{{
                                  extractExamScores(skill, "Listening")
                                    | score
                                }}
                                -
                              </span>

                              <span
                                :class="{
                                  'text-primary grade-text':
                                    scores.test_id != 2,
                                }"
                                >{{
                                  extractExamScores(skill, "Listening")
                                    | grade
                                }}({{
                                  extractExamScores(skill, "Listening")
                                    | cefr
                                }})</span
                              >
                            </span>
                            <span v-else>--</span>
                          </td>
                          <td>
                            <span
                              class="text-nowrap"
                              v-if="extractExamScores(skill, 'Speaking')"
                            >
                              <span v-if="scores.test_id != 2"
                                >{{
                                  extractExamScores(skill, "Speaking")
                                    | score
                                }}
                                -
                              </span>
                              <span
                                :class="{
                                  'text-primary grade-text':
                                    scores.test_id != 2,
                                }"
                                >{{
                                  extractExamScores(skill, "Speaking")
                                    | grade
                                }}({{
                                  extractExamScores(skill, "Speaking")
                                    | cefr
                                }})</span
                              >
                            </span>
                            <span v-else>--</span>
                          </td>
                          <td>
                            <span
                              class="text-nowrap"
                              v-if="extractExamScores(skill, 'Writing')"
                            >
                              <span v-if="scores.test_id != 2"
                                >{{
                                  extractExamScores(skill, "Writing")
                                    | score
                                }}
                                -
                              </span>
                              <span
                                :class="{
                                  'text-primary grade-text':
                                    scores.test_id != 2,
                                }"
                                >{{
                                  extractExamScores(skill, "Writing")
                                    | grade
                                }}({{
                                  extractExamScores(skill, "Writing")
                                    | cefr
                                }})</span
                              >
                            </span>
                            <span v-else>--</span>
                          </td>
                          <td>{{ avg }}</td>
                          <td>
                            {{
                              getResultTestScore(avg, scores.test_id) | cefr
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>
            <td colspan="8" v-if="getCandidateTestScores.length == 0">
              <p class="text-muted text-center">No Data Found</p>
            </td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </template>

<script>
import { mapActions, mapGetters } from "vuex";
import m from "moment";

  export default {
    name: "LanguageScores",

    props: {
    profile: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      collapsedIndex: [],


    }

  },
  computed: {
    ...mapGetters([
      "getNonMedicalQual",
      "getCandidateTestScores",
      "getCandidateId",
      "getTestScoresList",
      "getOverallResult",
      "getTestExamTypes",
    ]),
  },
  methods: {

    ...mapActions([
      "fetchNonMedicalQualNames",
      "fetchLanguageExam",
      "fetchCandidateTestScores",
      "fetchTestSections",
      "showLoader",
      "hideLoader",
      "fetchTestScores",
      "fetchTestExamTypes",
    ]),

    getResultTestScore(avg, test_id) {
      const res = this.getOverallResult({ test_id, score: avg });

      return res;
    },
    toggleInnerTable(index) {
      if (this.collapsedIndex.includes(index)) {
        const _index = this.collapsedIndex.findIndex((v) => v == index);
        if (_index >= 0) this.collapsedIndex.splice(_index, 1);
      } else {
        this.collapsedIndex.push(index);
      }
    },
    extractExamScores(skill, sectionName) {
      let _sectionName = sectionName;
      const test = skill.find(({ skills }) => skills === _sectionName) || null;
      const test_id = test?._rawData?.test_id;
      const score = test_id != 2 ? test.score : test.grade;
      return test
        ? {
            score: test.score,
            grade: test.grade,
            test_id: test?._rawData?.test_id,
            cefr_grade: this.getResultTestScore(score, test_id)?.cefr_grade,
          }
        : null;
    },
    requiredScore(data) {
      return data?.required_score_id
        ? this.getTestScoresList.find(
            (v) => v.test_score_id === data?.required_score_id
          )
        : null;
    },

    
  },
  filters: {
    grade(data) {
      return data?.grade || "";
    },
    cefr(data) {
      return data?.cefr_grade || "--";
    },
    dateConvert(data) {
      if (data) {
        const toDay = m();
        const givenDate = m(data);
        const months = toDay.diff(givenDate, "months");
        const isExpired = months > 24;
        let res = givenDate.format("MMM YYYY");
        res = isExpired ? `${res} (Expired)` : res;
        return res;
      }
      return "--";
    },
    dateFormat(date) {
      return date ? m(date, "YYYY-MM-DD").format("DD-MMM-YYYY") : "--";
    },
    score(data) {
      return data?.score || "--";
    },
    grade(data) {
      return data?.grade || "--";
    },
  },
  mounted() {
    let appendAction = [];
    if (!this.getNonMedicalQual?.length)
      appendAction.push(this.fetchNonMedicalQualNames());
    if (!this.getTestScoresList.length)
      appendAction.push(this.fetchTestScores());
    if (!this.getTestExamTypes.length)
      appendAction.push(this.fetchTestExamTypes());
    appendAction.push(this.fetchLanguageExam());
    appendAction.push(this.fetchCandidateTestScores());
    appendAction.push(this.fetchTestSections());
    this.showLoader();
    Promise.all(appendAction).then((res) => {
      this.hideLoader();
    });
  },

  };
</script>

<style lang="scss" scoped>
.arrow{
    transition: transform 0.2s linear;
}
.arrow.down {
  transform: rotate(90deg);
  transition: transform 0.2s linear;
}
</style>
