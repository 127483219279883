<template>
  <div class="pb-2">
    <a
      class="d-flex btn pl-0"
      @click="
        ($parent.isExpand.Canddocument = !$parent.isExpand.Canddocument),
          $parent.checkExpandAll()
      "
    >
      <span class="pr-1">
        <i
          class="text-primary fas"
          :class="
            $parent.isExpand.Canddocument ? 'fa-angle-down' : 'fa-angle-right'
          "
          alt="angle-down"
        />
      </span>
      <h5 class="text-primary text-left">
        Offer & Onboarding
        <span class="nowrap"
          >[ {{ totalRecords }} Record{{ totalRecords > 1 ? "s" : "" }}]</span
        >
      </h5>
    </a>
    <div v-if="$parent.isExpand.Canddocument">
      <div v-if="totalRecords">
        <OfferOnBoardDocument
          :offerDocument="offerDocument"
          :onBoardDocument="onBoardDocument"
          :profile="profile"
        />
      </div>
      <div v-else>
        <p class="text-center text-muted h6">No Document Information Found</p>
      </div>
    </div>
  </div>
</template>
<script>
import OfferOnBoardDocument from "@/containers/CandidateDetailsTalentFind/ProfileSection/OfferOnBoardDocument";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    onBoardDocument: {
      type: Array,
      default: () => [],
    },
    offerDocument: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    OfferOnBoardDocument,
  },
  computed: {
    totalRecords() {
      return this.onBoardDocument.length + this.offerDocument.length || 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  .iframeclass {
    width: 75%;
  }
  .modalPdfContent {
    height: 350px !important;
    overflow-y: scroll;
  }
  .wrapper {
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  @media all and (max-width: 480px) {
    .iframeclass {
      width: auto;
    }
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  color: #3c4b64;
  cursor: pointer;
}
.f-13 {
  font-size: 13px;
}
</style>
