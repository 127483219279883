<template>
  <div>
    <div v-if="isFetched">
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Type of Healthcare Facility</CCol>
        <CCol lg="7" class="text-black">{{ experience.organisation_type + ' ' +'(' +  experience.organisation_nature_label + ')' }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Facility Details</CCol>
        <CCol lg="7" class="text-black">{{ additionalInfo(experience) }}</CCol>
      </CRow>

      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Location of Facility</CCol>
        <CCol lg="7" class="text-black">{{ experience.organisation_location }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Bed Count / Size of Facility</CCol>
        <CCol lg="7" class="text-black">{{ experience.organisation_bedcount }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Number of Staff Reporting Directly</CCol>
        <CCol lg="7" class="text-black">{{ experience.direct_reports_count }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Current Employment</CCol>
        <CCol lg="7" class="text-black">{{ experience.is_current_employement }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Employment Period</CCol>
        <CCol lg="7" class="text-black"> {{ 
          experience.is_current_org
            ? formatDate(experience.start_month, experience.start_year)  + ' - ' + formatDate() + ' (Present)'
            : formatDate(experience.start_month, experience.start_year)  + ' - ' + formatDate(experience.end_month, experience.end_year)
          }}
        </CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Experience in years</CCol>
        <CCol lg="7" class="text-black">{{ experience.experience_in_years? experience.experience_in_years + ' yrs ' : "0 yrs" }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Level</CCol>
        <CCol lg="7" class="text-black">{{ experience.level_name || "--" }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Job Tittle / Role</CCol>
        <CCol lg="7" class="text-black">{{ experience.designation || "--" }}</CCol>
      </CRow>
    </div>  
  </div>
</template>
<script>
import m from "moment"
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: ["experience"],
  computed: {
    isFetched() {
      return (
        isObject(this.experience) && !isEmptyObjectCheck(this.experience)
      );
    },
  },
  methods: {
    additionalInfo(data) {
      if (data?.organisation_additional_info) {
        return  data?.organisation_additional_info != "Other"
          ? data?.organisation_additional_info
          : `Other - ${data?.organisation_additional_info_other}`
      }
    },
    totalExperience(data) {
      if (data?.experience_in_years != null && data?.experience_in_months != null) {
        return data?.experience_in_years > 1
          ? data.experience_in_years + ' yrs ' || "--"
          : data.experience_in_years + ' yrs ' + data.experience_in_months + ' months ' || "--" 
      } else {
        return "--"
      }
    },
    formatDate(month, year) {
        let date = month && year ? new Date(`${year},${month},01`) : new Date();
        return m(date)?.format("MMM YYYY") ? m(date)?.format("MMM YYYY") : "--";
    },
  }
}
</script>