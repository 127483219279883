<template>
  <div class="mx-3">
    <CTabs
      variant="tabs"
      :active-tab="activeTab"
      class="mt-3"
      @update:activeTab="activeTab = $event"
    >
      <CTab :title="`Offer  (${this.offerDocument.length})`">
        <OfferOnBoardDocTable :document="offerDocument" :profile="profile" />
      </CTab>
      <CTab :title="`Onboard  (${this.onBoardDocument.length})`">
        <OfferOnBoardDocTable :document="onBoardDocument" :profile="profile" />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import OfferOnBoardDocTable from "@/containers/CandidateDetailsTalentFind/ProfileSection/OfferOnBoardDocTable";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    onBoardDocument: {
      type: Array,
      default: () => [],
    },
    offerDocument: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    OfferOnBoardDocTable,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>