<template>
  <div>
    <div class="d-flex align-items-center justify-content-between pb-2">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.license = !$parent.isExpand.license),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.license ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          Licensure Information
          <span class="nowrap"
            >[{{ licenses.length || "0" }} Record{{
              licenses.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.license"
      >
        <CButton
          v-if="isEditable"
          class="btn-primary small mr-1"
          style="height: 28px"
          @click="addLicense()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >
        <CButton
          v-if="licenses.length"
          @click="license_step -= 1"
          :disabled="license_step == 0"
        >
          <CIcon name="cil-caret-left" />
        </CButton>
        <CButton
          v-if="paginatedLicenses.length"
          @click="license_step += 1"
          :disabled="license_step + 1 == paginatedLicenses.length"
        >
          <CIcon name="cil-caret-right" />
        </CButton>
      </div>
    </div>
    <div v-if="$parent.isExpand.license">
      <div v-if="paginatedLicenses.length">
        <CRow
          v-for="(data, parentIndex) in paginatedLicenses"
          :key="parentIndex"
        >
          <CCol lg="12" v-if="parentIndex === license_step">
            <div v-if="isMobile">
              <div class="d-flex justify-content-between pl-3 pr-2">
                <h6 class="pb-1 fw-600 pt-1">License {{ parentIndex + 1 }}</h6>
                <span v-if="isEditable">
                  <a class="hand-icon btn p-0" @click="showDocumentModal(data)">
                    <i class="fas fa-link"></i>
                  </a>
                  <a class="hand-icon btn p-0 ml-3" @click="editLicense(data)">
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a class="hand-icon btn p-0 ml-3" @click="deleteInfo(data)">
                    <i class="fas fa-trash"></i>
                  </a>
                </span>
              </div>
              <LicenseInfoCard :license="data" :profile="profile" />
            </div>
            <div v-else>
              <CRow>
                <CCol
                  lg="6"
                  class="gray-box"
                  v-for="(license, subIndex) in data"
                  :key="subIndex"
                >
                  <div class="d-flex justify-content-between pl-3 pr-2">
                    <h6 class="fw-600 py-1">
                      License {{ subIndex + parentIndex * 2 + 1 }}
                    </h6>
                    <span v-if="isEditable">
                      <a
                        class="hand-icon btn p-0"
                        @click="showDocumentModal(license)"
                      >
                        <i class="fas fa-link"></i>
                      </a>
                      <a
                        class="hand-icon btn p-0 ml-3"
                        @click="editLicense(license)"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </a>
                      <a class="hand-icon btn p-0 ml-3" @click="deleteInfo(license)">
                        <i class="fas fa-trash"></i>
                      </a>
                    </span>
                  </div>
                  <LicenseInfoCard :license="license" :profile="profile" />
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <p class="text-center text-muted h6">No License Information Found</p>
      </div>
    </div>
    <LicenseInfoEdit
      :licenseInfo="EditModal.licenseInfo"
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="licenseInfoModalCallBack"
      @licenseModalShow="licenseModalShow"
      v-if="EditModal.isShowPopup"
    />
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <DocumentMappingModal
      :isShowPopup="linkDocument.isShowPopup"
      :document_type_id="9"
      :label="linkDocument.label"
      :candidate_license_id="linkDocument.candidate_license_id"
      :document_ids="linkDocument.document_ids"
      @modalCallBack="linkModalCallBack"
    />
    <ShowMessageModal />
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import ShowMessageModal from "@/components/reusable/ShowMessageModal";
import LicenseInfoEdit from "@/containers/CandidateDetailsTalentFind/EditForm/LicenseInfoEdit";
import LicenseInfoCard from "@/containers/CandidateDetailsTalentFind/ProfileSection/License/LicenseInfoCard";
import DocumentMappingModal from "@/containers/CandidateProfile/DocumentMappingModal.vue";
import isJobSupplier from "@/mixins/isJobSupplier";
export default {
  mixins: [isJobSupplier],
  props: {
    profile: {
      type: Object,
      default: null,
    },
    licenses: {
      type: Array,
      default: () => [],
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LicenseInfoEdit,
    Modal,
    ShowMessageModal,
    LicenseInfoCard,
    DocumentMappingModal,
  },
  data() {
    return {
      license_step: 0,
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
        licenseInfo: null,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      candidate_license_id: null,
      linkDocument: {
        isShowPopup: false,
        label: "",
        candidate_license_id: null,
        document_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters(["currentUserType"]),
    isEditable() {
      return (
        ([Role.customerAdmin, Role.customerRecruiter].includes(
          this.currentUserType?.code || this.currentUserRole
        ) &&
          !this.isJobSupplier) ||
        false
      );
    },
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    paginatedLicenses() {
      if (!this.isMobile)
        return this.licenses.reduce((a, b, i) => {
          return i % 2 === 0 ? a.concat([this.licenses.slice(i, i + 2)]) : a;
        }, []);
      return this.licenses;
    },
  },
  methods: {
    ...mapActions([
      "uploadLicense",
      "updateLicense",
      "fetchLicense",
      "deleteCandidateLicense",
      "fetchPQRLevels",
    ]),
    addLicense() {
      this.$parent.tooltipHide();
      this.EditModal.isShowPopup = true;
      this.EditModal.licenseInfo = null;
      return false;
    },
    editLicense(data) {
      this.EditModal.isShowPopup = true;
      this.EditModal.licenseInfo = data;
      return false;
    },
    showDocumentModal(data) {
      this.linkDocument.candidate_license_id = data.candidate_license_id
        ? data.candidate_license_id
        : null;
      this.linkDocument.document_ids = data.document_ids || [];
      this.linkDocument.label = data.issue_authority_name;
      this.linkDocument.isShowPopup = true;
      return false;
    },
    linkModalCallBack(action, value, candidate_license_id) {
      if (action == true) {
        let payload = {
          candidate_uid: this.profile.candidate_uid,
          candidate_license_id: candidate_license_id,
          document_ids: value?.length ? value : null,
        };
        this.updateLicense(payload).then((res) => {
          this.fetchLicense();
        });
      }
      this.linkDocument.isShowPopup = false;
    },
    deleteInfo(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this License Info  - ${data?.country_name} ?`;
      this.candidate_license_id = data?.candidate_license_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        if (this.candidate_license_id !== null) {
          let appendAction = this.deleteCandidateLicense(
            this.candidate_license_id
          );
          Promise.all([appendAction]).then((res) => {
            this.fetchLicense();
          });
        }
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.candidate_license_id = null;
    },
    licenseInfoModalCallBack(action, data) {
      this.EditModal.isShowPopup = false;
      if (action === true) {
        let appendAction;
        if (this.EditModal.licenseInfo) {
          appendAction = this.updateLicense(data);
        } else {
          appendAction = this.uploadLicense(data);
        }
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.EditModal.isShowPopup = false;
            this.fetchLicense();
            this.fetchPQRLevels();
          }
        });
      }
    },
    licenseModalShow(action) {
      if (action === true) {
        this.EditModal.isShowPopup = true;
      } else this.EditModal.isShowPopup = false;
    },
  },
};
</script>
