<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.experience = !$parent.isExpand.experience),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.experience
                ? 'fa-angle-down'
                : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          Experience Information
          <span class="nowrap"
            >[{{ ExperienceInfo.length }} Record{{
              ExperienceInfo.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.experience"
      >
        <CButton
          v-if="isEditable"
          class="btn-primary small mb-2 mr-2 mt-1"
          style="height: 28px"
          @click="addExperienceInfo()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >
        <CButton
          v-if="ExperienceInfo.length"
          @click="exp_step -= 1"
          :disabled="exp_step == 0"
        >
          <CIcon name="cil-caret-left" />
        </CButton>
        <CButton
          v-if="paginateExperienceInfo.length"
          @click="exp_step += 1"
          :disabled="exp_step + 1 == paginateExperienceInfo.length"
        >
          <CIcon name="cil-caret-right" />
        </CButton>
      </div>
    </div>
    <div v-if="$parent.isExpand.experience">
      <div v-if="ExperienceInfo.length">
        <CRow
          v-for="(data, parentIndex) in paginateExperienceInfo"
          :key="parentIndex"
        >
          <CCol lg="12" v-if="parentIndex === exp_step">
            
              <CRow v-if="isMobile">
                <CCol lg="6">
                  <div class="gray-box" style="min-height: 216px;">
                    <div class="d-flex justify-content-between pl-3 pr-2">
                      <h6 class="fw-600 py-1">Experience {{ parentIndex + 1 }}</h6>
                      <span v-if="isEditable">
                        <a
                          class="hand-icon btn p-0"
                          @click="editExperienceInfo(data)"
                        >
                          <i class="fas fa-pencil-alt ml-3"></i>
                        </a>
                        <a
                          class="hand-icon btn p-0"
                          @click="deleteExperienceInfo(data)"
                        >
                          <i class="fas fa-trash ml-3"></i>
                        </a>
                      </span>
                    </div>
                    <ExperienceInfoCard :experience="data" />
                  </div>
                </CCol>
              </CRow> 
              <CRow v-else>
                <CCol
                  lg="6" 
                  v-for="(exp, subIndex) in data"
                  :key="subIndex"
                >
                <div class="gray-box" style="min-height: 216px;">
                  <div class="d-flex justify-content-between pl-3 pr-2">
                    <h6 class="fw-600 py-1">
                      Experience {{ subIndex + parentIndex * 2 + 1 }}
                    </h6>
                    <span v-if="isEditable">
                      <a
                        class="hand-icon btn p-0"
                        @click="editExperienceInfo(exp)"
                      >
                        <i class="fas fa-pencil-alt ml-3"></i>
                      </a>
                      <a
                        class="hand-icon btn p-0"
                        @click="deleteExperienceInfo(exp)"
                      >
                        <i class="fas fa-trash ml-3"></i>
                      </a>
                    </span>
                  </div>
                  <ExperienceInfoCard :experience="exp" />
                </div>
                </CCol>
              </CRow>
            
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <p class="text-center text-muted h6">
          No Experience Information Found
        </p>
      </div>
    </div>
    <ExperienceInfoForm
      :candidateInfo="profile"
      :ExperienceInfo="ExperienceModal.data"
      :isShowPopup="ExperienceModal.isShowPopup"
      :isEdit="ExperienceModal.isEdit"
      @modalCallBack="ExperienceInfoModalCallBack"
      v-if="ExperienceModal.isShowPopup"
    >
    </ExperienceInfoForm>
    <Modal
      v-if="confirmModal.isShowPopup"
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="experienceModalcallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ExperienceInfoCard from "@/containers/CandidateDetailsTalentFind/ProfileSection/ExperienceInfo/ExperienceInfoCard";
import ExperienceInfoForm from "@/containers/CandidateDetailsTalentFind/EditForm/ExperienceInfoForm";
import Modal from "@/components/reusable/Modal";
import isJobSupplier from "@/mixins/isJobSupplier";
export default {
  mixins: [isJobSupplier],
  props: {
    ExperienceInfo: {
      type: Array,
      default: () => [],
    },
    profile: {
      type: Object,
      default: () => null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ExperienceInfoForm,
    ExperienceInfoCard,
    Modal,
  },
  data() {
    return {
      exp_step: 0,
      Role,
      currentUserRole: getScope(),
      ExperienceModal: {
        data: null,
        isShowPopup: false,
        isEdit: false,
      },
      confirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    ...mapGetters(["currentUserType"]),
    isEditable() {
      return (
        [Role.customerAdmin, Role.customerRecruiter].includes(
          this.currentUserType?.code || this.currentUserRole
        ) && !this.isJobSupplier || false
      );
    },
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    paginateExperienceInfo() {
      if (!this.isMobile)
        return this.ExperienceInfo.reduce((a, b, i) => {
          return i % 2 === 0
            ? a.concat([this.ExperienceInfo.slice(i, i + 2)])
            : a;
        }, []);
      return this.ExperienceInfo;
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidateExperiences",
      "uploadCandidateExperience",
      "updateCandidateExperience",
      "deleteCandidateExperience",
      "showToast",
    ]),
    addExperienceInfo() {
      this.$parent.tooltipHide();
      this.ExperienceModal.isEdit = false;
      this.ExperienceModal.data = null;
      this.ExperienceModal.isShowPopup = true;
    },
    editExperienceInfo(data) {
      this.ExperienceModal.isEdit = true;
      this.ExperienceModal.data = data;
      this.ExperienceModal.isShowPopup = true;
    },
    ExperienceInfoModalCallBack(action, expPayload) {
      if(this.ExperienceModal.isEdit) this.exp_id = expPayload?.experience_id;
      if (action === false) this.ExperienceModal.isShowPopup = false;
      else {
        this.saveExperience(expPayload);
      }
    },
    saveExperience(expPayload) {
      let appendAction = null;
        if (
          this.ExperienceModal.data === null &&
          this.ExperienceModal.isEdit === false
        ) {
          appendAction = this.uploadCandidateExperience(expPayload);
        } else if (this.ExperienceModal.isEdit) {
          let payload = {
            ...expPayload,
            experience_id: expPayload.experience_id
          }
          appendAction = this.updateCandidateExperience(payload);
        }
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.ExperienceModal.isShowPopup = false;
            this.ExperienceModal.isEdit = false;
            this.ExperienceModal.data = null;
          }
        });
    },
    deleteExperienceInfo(data) {
      this.confirmModal.modalContent = `Do you want to delete this Experience`;
      this.exp_id = data.experience_id;
      this.confirmModal.isShowPopup = true;
    },
    experienceModalcallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateExperience(this.exp_id);
      }
      this.exp_id = null;
      this.confirmModal.isShowPopup = false;
    },
  },
};
</script>
