import { render, staticRenderFns } from "./AdditionalTrainingInfo.vue?vue&type=template&id=c4a7a8c4&scoped=true"
import script from "./AdditionalTrainingInfo.vue?vue&type=script&lang=js"
export * from "./AdditionalTrainingInfo.vue?vue&type=script&lang=js"
import style0 from "./AdditionalTrainingInfo.vue?vue&type=style&index=0&id=c4a7a8c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4a7a8c4",
  null
  
)

export default component.exports