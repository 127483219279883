<template>
  <div class="commom-comment-modal">
    <CModal class="text-black" :show.sync="isShowPopup" :close-on-backdrop="false" color="primary">
    <template #header>
      <h6 class="modal-title">Comments History</h6>
      <CButtonClose @click="modalCallBack" class="text-black" />
    </template>
    <template #footer>
      <CButton
        type="button"
        color="primary"
        variant="outline"
        class="px-4"
        @click="modalCallBack"
      >Close</CButton>
    </template>
    <ValidationObserver ref="commentsform" v-slot="{ handleSubmit }">
      <form name="commentsform" @submit.prevent="handleSubmit(PostCandidateComment)">
        <div class="p-2">
          <CRow class="row mb-3">
            <label class="col-lg-12 col-md-12 required">Comment</label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <ValidationProvider rules="required" v-slot="{errors}">
                <TextareaInput
                  name="comment"
                  :value="comment.comment"
                  @change="handleInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </CRow>
          <CRow class="row mb-3">
            <div class="col-lg-12 col-md-12 col-sm-12 pr-3">
              <CButton type="button" color="primary" :style="'float:right'" class="pr-3" @click="PostCandidateComment()">{{btnText}}</CButton>
            </div>
          </CRow>
        </div>     
      </form>
    </ValidationObserver>  
    <CandidateComments :candidateID="candidateID" />
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import CandidateComments from "@/containers/CandidateDetailsTalentFind/ProfileSection/CandidateComments"
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false
    },
    candidateID: {
      type: Number,
      default: null
    }
  },
  components: {
    TextareaInput,
    CandidateComments
  },
  data() {
    return {
      comment: {
        comment: ""
      },
      btnText: "Comment"
    }
  },
  mounted() {
    this.fetchCandidateCommonComments(this.candidateID)
  },
  methods: {
    ...mapActions(["fetchCandidateCommonComments","postCandidateComments", "showToast"]),
    handleInput(name, value) {
      Vue.set(this.comment, name, value);
    },
    modalCallBack() {
      this.$emit("commentModalCallBack", "comment");
    },
    async PostCandidateComment() {
      const isValid = await this.$refs.commentsform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.btnText = "Adding..."
      let payload = {
        candidate_id: this.candidateID,
        comments: this.comment.comment
      }
      let appendAction = this.postCandidateComments(payload)
      Promise.all([appendAction]).then((res) => {
        if (res) {
          this.btnText = "Comment";
          this.$refs.commentsform.reset();
          this.comment.comment = null;
          this.fetchCandidateCommonComments(this.candidateID)
        }
      });
    }
  }
}
</script>