<template>
  <div>
    <div v-if="isFetched">
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">License Number</CCol>
        <CCol col="6" class="text-black">{{
          license.license_number || "--"
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">Country</CCol>
        <CCol lg="6" class="text-black">{{
          license.country_name || "--"
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">Licensing Authority</CCol>
        <CCol lg="6" class="text-black">{{
          licenseBoard(license) || "--"
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">First Registration Date</CCol>
        <CCol lg="6" class="text-black">{{ license.issue_date | moment }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">Level / Type</CCol>
        <CCol lg="6" class="text-black">{{ license.level_name || "--" }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">Date Level Obtained</CCol>
        <CCol lg="6" class="text-black">{{
          license.date_acquired | monthYear
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="6" class="text-gray">Active</CCol>
        <CCol lg="6" class="text-black">{{
          license.current_license | yesORno
        }}</CCol>
      </CRow>
      <CRow v-if="showDocument" class="profileView">
        <CCol lg="6" class="text-gray">Related Documents</CCol>
        <CCol lg="6" class="text-black">
          <span v-if="license.documents && license.documents.length">
            <a
              class="text-primary"
              style="cursor: pointer"
              v-for="(i, index) in license.documents"
              :key="index"
              @click="preview(i)"
              >{{ index == 0 ? i.document_name : `, ${i.document_name}` }}
            </a>
          </span>
          <span v-else> {{ "--" }} </span>
        </CCol>
      </CRow>
      <PreviewModal
        modalTitle="Document Preview"
        modalColor="primary"
        :buttons="['Ok']"
        size="lg"
        :isShowPopup="previewModal.isShowPopup"
        :modalCallBack="previewModalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :closeOnBackdrop="false"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import PreviewModal from "@/components/reusable/PreviewModal.vue";
import { BASE_URL } from "@/service_urls";
import pdf from "vue-pdf";
import { mapGetters } from "vuex";
export default {
  props: ["license", "profile"],
  components: {
    PreviewModal,
  },
  data() {
    return {
      previewModal: {
        isShowPopup: false,
        isPreview: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["isJobSupplierFromAccessToken", "isUKMainAgency"]),
    isFetched() {
      return isObject(this.license) && !isEmptyObjectCheck(this.license);
    },
    showDocument() {
      return !this.isJobSupplierFromAccessToken
        ? true
        : this.isJobSupplierFromAccessToken &&
          this.profile?.currently_in_recruitment_process
        ? true
        : false;
    },
    showDocument() {
      return !this.isJobSupplierFromAccessToken
        ? true
        : this.isJobSupplierFromAccessToken &&
          this.profile?.currently_in_recruitment_process
        ? true
        : false;
    },
  },
  methods: {
    showPreview(data) {
      const { uuid_link_to_doc } = data;
      //  const _baseUrl = BASE_URL;
      //  const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      const _base_domain = "https://" + window.location.host;
      return appendAccessToken(`${_base_domain}${uuid_link_to_doc}`);
    },
    preview(data) {
      data.link_to_document = this.showPreview(data);
      let document_ext = data?.document_ext?.document_ext;

      if (
        document_ext === "PNG" ||
        document_ext === "JPG" ||
        document_ext === "JPEG" ||
        document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        document_ext === "TXT" ||
        document_ext === "EPS" ||
        document_ext === "TIF" ||
        document_ext === "TIFF" ||
        document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    licenseBoard(data) {
      if (data) {
        return data?.issue_authority_id == "Others" &&
          data?.other_issue_authority
          ? "Other - " + data?.other_issue_authority
          : data?.issue_authority_name;
      }
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
    yesORno(license) {
      if (license !== null) return license ? "Yes" : "No";
      return "--";
    },
    monthYear(license) {
      let month_year = null;
      if (license) {
        let mothYeardata = license.split("-");
        if (mothYeardata[1])
          month_year = moment.monthsShort()[mothYeardata[1] - 1];
        if (mothYeardata[0]) month_year = `${month_year} ${mothYeardata[0]}`;
      }
      return month_year || "--";
    },
  },
};
</script>
