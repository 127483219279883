<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.additionalTraining =
            !$parent.isExpand.additionalTraining),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas fa-angle-right arrow"
            :class="{'down':$parent.isExpand.additionalTraining}"
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">Additional Qualifications and Training</h5>
      </a>
    </div>
    <div v-if="$parent.isExpand.additionalTraining" class="gray-box">
      <CRow class="profileView">
        <CCol col="5" class="text-gray">Course Type</CCol>
        <CCol lg="7" class="text-black">{{ additionalQualification?.course_test_type?.course_test_type || "--"}}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Language</CCol>
        <CCol lg="7" class="text-black">{{ additionalQualification?.language?.language || "--"}}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Course Name</CCol>
        <CCol lg="7" class="text-black">{{ additionalQualification?.test?.test_name || "--"}}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Course Start Date</CCol>
        <CCol lg="7" class="text-black"
          >{{ additionalQualification?.course_start_date || "--" }}
        </CCol>
      </CRow>
      <CRow class="profileView" v-if="profile.isStudent">
        <CCol lg="5" class="text-gray">Course End Date</CCol>
        <CCol lg="7" class="text-black"
          >{{ additionalQualification?.course_end_date || "--" }}
        </CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Sponsorship Start Date</CCol>
        <CCol lg="7" class="text-black">{{
          additionalQualification?.candidate_sponsor?.start_date || "--" 
         }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Sponsorship End Date</CCol>
        <CCol lg="7" class="text-black">{{
          additionalQualification?.candidate_sponsor?.end_date || "--"
        }}</CCol>
      </CRow>
    </div>

  </div>

  </template>
  <script>
  export default {
    name: "AdditionalTraining",
    props: {
    profile: {
      type: Object,
      default: () => null,
    }
  },
  computed: {
      additionalQualification() {
      return this.profile?.additional_language_qualification;
    },
  },
  };
  </script>
  <style lang="scss" scoped>
  .arrow{
      transition: transform 0.2s linear;
  }
  .arrow.down {
    transform: rotate(90deg);
    transition: transform 0.2s linear;
  }
  </style>
  